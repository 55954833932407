.app-item {
  flex: 1;
  width: 100%;
  overflow-x: auto;
}

.layoutSidenav {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}
