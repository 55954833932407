.company-img-preview {
  position: relative;
}

.delete-company-image-btn {
  position: absolute;
  z-index: 4;
  right: 1rem;
  bottom: 1rem;
}

.company-img-preview > img {
  height: 154px;
  width: 415px;
  border-radius: 8px;
  max-width: 100%;
}
/* 
.company-img-preview > img {
  max-width: 100%;
  height: 154px;
  border-radius: 8px;
} */

.company_logo_wrapper > span {
  font-size: 14px;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  padding: 3px 0 0 8px;
  cursor: pointer;
}

.company_logo_wrapper > input {
  display: none;
}

.company_logo {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 92px;
  height: 92px;
  cursor: pointer;
}

.color-picker {
  width: 127px !important;
  height: 100% !important;
}

.color-pallete {
  height: 88px;
}
.color-pallete > input {
  padding: 5px;
}

.empty-img {
  width: 90px;
  height: 36px;
  /* border-radius: 50px; */
  /* background: gray; */
  padding: 45px;
}

.empty-img-svg > svg {
  width: 45px;
  height: 45px;
}

.update-footer {
  padding: 0 !important;
  margin: auto !important;
}

.company-description > p {
  margin: 0 !important;
}

.company-description > h1 {
  font-family: "Red Hat Display", sans-serif !important;
}
