.span-title{
    font-size: 14px;
    font-weight: 700;
}

.span-text{
    font-size: 12px;
    font-weight: 400;
}

.span-head{
    font-size: 18px;
    font-weight: 900;
    font-family: 'Millik', sans-serif;
}

.span-head-text{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Red Hat Displat', sans-serif;
    margin: 10px 0;
}

.hr{
    color: #cbcbcb;
}

li{
    padding: 10px 0;
}

.steps{
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.steps-head{
    font-size: 14px;
    font-weight: 900;
}

.steps-text{
    font-size: 12px;
    font-weight: 600;
}