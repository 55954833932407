@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Labrada:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,800;1,900&family=Sorts+Mill+Goudy:ital@0;1&display=swap");

.mainAppWrapper {
  display: flex !important;
  justify-content: start;
  width: 100%;
  overflow-x: hidden;
}

.layoutSidenav_nav {
  z-index: 50 !important;
}

.sideNav,
.hidde-sideNav {
  z-index: 80 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small-text-bold-success {
  font-size: 12px;
  font-weight: 800;
  color: #0a7e32;
}

.small-text-light-success {
  font-size: 12px;
  font-weight: 800;
  color: #0a7e32;
}
.medium-text-bold-success {
  font-size: 16px;
  font-weight: 800;
  color: #0a7e32;
}

.hamburger {
  display: none;
}

.bage {
  position: absolute;
  top: -5px;
  right: -10px;
  transform: translate(50%, -50%);
  min-width: 1rem;
  height: 1rem;
  line-height: 0.9rem;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #335f32;
  color: #ffffff;
  background-color: #335f32;
  border-radius: 50%;
  padding: 0 3px;
}

.truncated-cell {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media (max-width: 1020px) {
  /* Hide the side navigation */
  .sideNav,
  .hidde-sideNav {
    display: none;
  }

  /* Show the hamburger icon */
  .hamburger {
    display: block; /* Change from 'none' to 'block' */
  }
  .main-title {
    margin-top: 15px;
  }
  .main-title > h3 {
    font-size: 30px;
  }

  /* Hide the layout navigation */
  .layoutSidenav_nav {
    display: none;
  }

  :root {
    --sidebar-width: 0;
  }

  /* Adjust the width of app items */
  .app-item {
    width: 100% !important;
  }

  /* Additional styling for the hamburger icon if necessary */
  .hamburger {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
  }
}
