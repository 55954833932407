.onboarding_dev_img, .onboarding_dev_img > img{
    max-height: 75px;
}

.imgae-banner > img{
   object-fit: scale-down !important;
}

.onboarding_dev_title{
    font-family: 'Millik', sans-serif;
    font-size: 40px;
    color: #335F32;
}

/* Custom <select> dropdown */
.custom-select {
    position: relative;
    width: 400px;
    max-width: 100%;
    font-size: 1.15rem;
    color: #000;
  }
  
  .select-button {
    width: 100%;
    background-color: #F0F0F0;
    padding: 0.675em;
    border: 1px solid #caced1;
    border-radius: 100px;
    cursor: pointer;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .selected-value {
    text-align: left;
  }

  .required {
    color: red;
  }
  
  .arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
    transition: transform ease-in-out 0.3s;
  }

  .select-dropdown {
    position: absolute;
    list-style: none;
    width: 100%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid #caced1;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    transition: 0.5s ease;
  }
  
  .select-dropdown:focus-within {
    box-shadow: 0 10px 25px rgba(94, 108, 233, 0.6);
  }
  
  .select-dropdown li {
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
    border-bottom: solid 1px #0000001A;
  }
  
  .select-dropdown li label {
    width: 100%;
    padding: 2px 10px;
    cursor: pointer;
  }
  
  .select-dropdown::-webkit-scrollbar {
    width: 7px;
  }
  .select-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
  }
  
  .select-dropdown::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
  }


  .select-dropdown li:hover,
.select-dropdown input:checked ~ label {
  background-color: #f2f2f2;
}

.select-dropdown input:focus ~ label {
  background-color: #dfdfdf;
}



.select-dropdown input[type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }

/*   
.company-img-preview {
  position: relative;
}

.delete-company-image-btn {
  position: absolute;
  top: 6rem;
  right: -2rem;
} */
