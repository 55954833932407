:root {
  --sidebar-width: 17rem;
}

body {
  margin: 0;
  font-family: "Red Hat Display", -apple-system, BlinkMacSystemFont, "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h1,
h1 {
  font-family: "Millik", sans-serif;
  font-size: 40px;
  font-weight: 500;
}

.h2,
h2 {
  font-family: "Millik", sans-serif;
  font-size: 32px;
  font-weight: 700;
}

.h3,
h3 {
  font-family: "Millik", sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.h4,
h4 {
  font-family: "Millik", sans-serif;
  font-size: 20px;
  font-weight: 300;
}

.h5,
h5 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.h6,
h6 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 900;
}

.p,
p {
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  margin: 0;
}

.anchor {
  font-size: 14px;
  font-weight: bold;
  color: #335f32;
  border-bottom: 1px solid transparent;
  transition: all 0.25s ease;
  opacity: 0.8;
  cursor: pointer;

  &:active {
    color: #a35a05;
  }
}

.anchor:hover {
  opacity: 1;
  border-bottom: 1px solid #335f32;
}

.dropdown {
  background-color: none !important;
  background: none !important;
}

.dropdown-toggle {
  background-color: none !important;
  background: none !important;
  border: none !important;
}

.dropdown > button::after {
  display: none !important;
}

.dropdown-menu {
  border-radius: 8px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.dropdown-item {
  font-size: 0.9rem !important;
  border-bottom: 1px solid #F0F0F0 !important;
  background-color: white !important;
  padding: 0.6rem !important;
  padding-right: 1rem;
}

.dropdown-item:last-child {
  border-bottom: none;
}

a.dropdown-item:active {
  color: black;
  background-color: #edebeb !important;
}

.circle_bar {
  display: inline-block;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-color: gray;
  margin-bottom: 0.1rem;
}

.table-head {
  font-size: 14px;
  font-weight: bold;
}

.toast-header {
  font-family: "Millik", sans-serif;
  font-size: 1rem;
  margin: 0;
}

.toast-text {
  font-size: 0.8rem;
}

.row-data {
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.price {
  color: #335f32;
}

.dot {
  width: 6px;
  height: 6px;
  color: #4b4b4b;
}

.toolTipText {
  position: absolute;
  bottom: 2rem;

  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid grey;
  background-color: #fcfcfc;
  width: 25rem;
  overflow: hidden;
  z-index: 1000;
}

.toolTipText > p {
  text-align: center;
}

.main_wrapper {
  padding: 5px 20px;
}

.Toastify__toast {
  min-height: 80px;
  font-family: "Red Hat Display", sans-serif;
}

.Toastify__toast-body {
  font-size: 13px !important;
}

.form-check-input:checked {
  background-color: #335f32 !important;
  border: 2px solid #335f32 !important;
  width: 1.3em;
  height: 1.3em;
  outline: none !important;
  box-shadow: inset 0 2px 5px #335f32 !important;
}

.greenSuccessPill {
  display: inline-block;

  color: #335f32;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #335f32;
  border-radius: 100px !important;
  background-color: #dfffde;
  padding: 2px 7px;
}

.redDangerPill {
  display: inline-block;

  color: #b90505;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #b90505;
  border-radius: 100px !important;
  background-color: #ffe0e0;
  padding: 2px 7px;
}

.yellowPendingPill {
  display: inline-block;

  color: #6F5F09;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #6F5F09;
  border-radius: 100px !important;
  background-color: #feffd9;
  padding: 3px 10px;
}

.constuctionIcon > svg {
 height: 1.6rem !important;
}

@font-face {
  font-family: "Millik";
  src: url("../public/assets/fonts/Millik.ttf") format("truetype");
}

@media (min-width: 575px) {
  .custom_grid {
    width: 50% !important;
  }
}

@media (min-width: 800px) {
  .custom_grid {
    width: 33.33% !important;
  }
}

@media (min-width: 1150px) {
  .custom_grid {
    width: 25% !important;
  }
}

@media (min-width: 1460px) {
  .custom_grid {
    width: 20% !important;
  }
}