.affordabilityWrapper {
    position: relative;
}

.affordableDisplay {
    position: absolute;
    /* top: 0.5rem; */
    left: -11rem;
}


.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    background: #DDF8E7;
    width: 110px !important;
    height: 35px;
    border: 1px solid #335F32;
    border-radius: 100px;
    margin: 0 !important;
    cursor: pointer;
}

.wrapper-danger{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    background: #FFDEDE;
    width: 115px !important;
    height: 35px;
    border: 1px solid #D10808;
    border-radius: 100px;
    margin: 0 !important;
    cursor: pointer;
} 

.text{
    color: #335F32;
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 0.1rem;
}

.text-ndanger{
    color: #D10808;
    font-size: 12px;
    font-weight: 800;
    margin-bottom: -0.1rem;
}

.icon-left{
    padding-bottom: 2px;
    margin-bottom: 0.2rem;
}

.icon-right{
    display: inline-flex;
    border: 2px solid #0A7E32;
    border-radius: 50%;
    height: 17px;
    width: 17px;
} 
.icon-right-danger{
    display: inline-flex;
    border: 2px solid #D10808;
    border-radius: 50%;
    height: 17px;
    width: 17px;
} 

.description{
    border: 2px solid #335F32;
    border-radius: 8px;
    position: absolute;
    bottom: 60px !important;
    right: 0px;
    background: white;
    z-index: 20;
    height: 150px !important;
    width: 448px !important;
    padding: 25px; 
}

.description-text{
    font-size: 12px;
    font-family: 'Red Hat Display', sans-serif;
    text-wrap: wrap;
    font-weight: 500;
}

.description-title{
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
}