.prop_icon > span > svg {
  fill: #335f32 !important;
  height: 16px !important;
  margin: 0;
}
.prop_icon_location > span > svg {
  fill: #335f32 !important;
  height: 20px !important;
  margin: 0;
}

.viewDoc > svg {
  fill: #335f32 !important;
  height: 20px !important;
  width: 24px !important;
  margin: 0;
}

.carousel {
  display: flex; /* Use flexbox */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.col-md-2 {
  flex: 1; /* Make each tab take equal space */
  display: flex; /* Ensure the elements inside the tab are aligned horizontally */
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center items horizontally */
}

.row {
  width: 100%; /* Ensure each row takes the full width of its parent */
}

.d-flex {
  display: flex; /* Use flexbox */
}

.tab-name {
  text-align: center;
}

hr {
  width: 100%;
  margin: 0;
}

.rounded-circle {
  left: 38%;
}

.priceChangeIcon > svg {
  height: 0.8rem;
  stroke: 0 !important;
  margin-bottom: 0.3rem;
}

.tab-icon > svg {
  height: 30px;
}

.application_details {
  height: 100%;
  min-height: 100vh;
}

.legal-search_payment_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
  width: 100%;
  max-width: 25rem;
  border-radius: 15px;
  border: 1px solid #cecece;
  background-color: #f5f5f5;
  padding: 1rem;
}

.message_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 90%;
}

.customer_messages,
.agent_messages {
  font-size: 0.8rem;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 2px 2px #0000001a !important;
  background-color: #fdfdfd;
  padding: 1rem 0.8rem;
  width: 49%;
}

.agent_messages {
  justify-content: end;
  background-color: #f4fff8;
  margin-left: auto;
}

.reply_container {
  display: flex;
  flex-direction: column;
}

.send_button {
  justify-content: end;
  margin-left: auto;
}

@media (max-width: 768px) {
  .customer_messages,
  .agent_messages {
    width: 80%;
  }
}
