.pr-card {
  max-width: 316px !important;
  height: 200px !important;
  border: none !important;
  overflow-x: hidden;
  overflow-y: visible;
  cursor: pointer;
  transition: transform 0.2s ease-in;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
}

.slider-body {
  height: 50% !important;
  overflow-x: hidden;
  /* overflow-y:visib */
}

.card-details {
  height: 50% !important;
}

.pr-title,
.pr-desc,
.pr-hou {
  font-family: "Red Had Display", sans-serif;
}

.pr-title > h6 {
    font-weight: bold;
    font-size: 18px;
  }
  
  .pr-desc {
    font-size: 16px;
    overflow: hidden;
    margin: 0 !important;
  }

.pr-hou > span {
  font-weight: bold;
  font-size: 15px;
}

.pr-carousel-body {
  max-height: 658px;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
}

.p-carousel-next {
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 15px;
  margin-right: 10px;
  color: #335f32;
  background-color: #ffffff;
}

.p-carousel-next > svg {
  width: 29px;
  height: 29px;
  fill: #335f32;
}

.p-carousel-prev {
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 15px;
  margin-right: 10px;
  color: #335f32;
  background-color: #ffffff;
}

.p-disabled {
  border: 1px solid #70707069;
  background-color: #70707073;
  color: #ffffff90;
}

.p-carousel-prev > svg {
  width: 29px;
  height: 29px;
  fill: #335f32;
}

.pr-carousel-body_alt {
  max-height: 658px;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}

.pr-cr-cd {
  padding: 0px !important;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.pr-cr-cd > img {
  width: 315px;
  height: 94px;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
}

.slick-arrow {
  border: 2px solid #707070;
  border-radius: 50%;
  height: 49px;
  width: 49px;
  background-color: #335f32;
  z-index: 1;
}

.slick-slide {
  width: 345px;
}

.dt-bn {
  border: 1px solid #d8d8d8;
  border-radius: 8px !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
  padding: 0;
}

.rdt_TableHeadRow {
  background: #f0f0f0 !important;
  height: 62px;
}

.pr-sli {
  max-height: 200px !important;
}

.slick-list {
  height: 200px !important;
}

.search-form {
  margin: auto auto 20px auto;
}

.wrpper {
  margin: 30px auto;
}

.pr-card:hover {
  transform: scale(1.03);
  transition: transform 0.2s ease-in;
}

.estate {
  color: inherit !important;
  text-decoration: none;
}
.card-footer {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.create-estate {
  font-size: 16px;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  color: #335f32 !important;
}

.slick-track {
  width: auto !important;
  padding-left: 1.5rem;
  padding-right: 75px;
}

.slick-slide {
  width: 360px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 0;
  opacity: 4.75;
  color: white;
  border: 1px solid black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 50%;
  background: aquamarine;
}
