.card_container {
  border-radius: 1rem;
  border: 2px solid grey;
  overflow: hidden;
}

.card_title {
  font-weight: bold;
  text-transform: uppercase;
}

.card_description {
  padding: 0 1rem 1rem;
}
