.prop-doc {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;

    width: 300px;
    /* height: 256px; */
    overflow: hidden;
    border-radius: 15px;
    background-color: blue;
}

.prop-content {
    margin: auto;
    background-color: yellow;
}