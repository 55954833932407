/* .autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
}

.autocomplete-autocomplete {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  background: white;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.autocomplete-option {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-option:hover {
  background-color: #f0f0f0;
}

.autocomplete-loading,
.autocomplete-error,
.autocomplete-no-options {
  padding: 8px;
  color: #999;
  text-align: center;
} */

.autocomplete-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border: 2px solid transparent;
  border-radius: 100px;
  background-color: #F0F0F0;
  transition: all 0.25s ease;
  /* overflow: hidden; */
  position: relative;
}

.autocomplete-group-error {
  border: 2px solid red !important;
  background-color: white;
}

.autocomplete-group:focus-within:not(.autocomplete-group-error) {
  border: 2px solid green !important;
  outline: none !important;
  background-color: white;
}

.autocomplete-group:hover:not(.autocomplete-group-error) {
  border: 1px solid green;
  background-color: white;
}

.autocomplete-input, 
.autocomplete-input:focus {
  font-size: 14px;
  font-weight: normal !important;
  font-family: "Red Hat Display", sans-serif;
  border: none;
  border-radius: 100px;
  background-color: transparent;
  outline: none;
  width: 100%;
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}

.form-error {
  position: absolute;
  left: 0;
  bottom: -1.2rem;

  padding: 0 16px 0 8px;
  font-family: "Red Hat Display";
  font-size: 13px;
  font-weight: 500;
  color: red;
}

.form-hintText {
  position: absolute;
  left: 0;
  bottom: -1.2rem;

  padding: 0 16px 0 8px;
  font-family: "Red Hat Display";
  font-size: 11px;
  font-weight: 500;
  /* color: ${colors.red}; */
}

.autocomplete-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3rem;
  z-index: 1000;
  border-radius: 5px;
  border: 1px solid grey;
  background: white;
  max-height: 200px;
  overflow-y: auto;
}

.autocomplete-loading,
.autocomplete-error,
.autocomplete-no-options {
  padding: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.autocomplete-option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.autocomplete-option:hover {
  background: #f0f0f0;
}

